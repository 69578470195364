<template>
  <input type="password" :class="className" :id="id" :placeholder="placeholder" v-model="modelValueData">
  <div v-if="modelValue.length > 0">
    <div :class="strengths[strength].color">{{ $t(`password.yourPasswordStrength`, {name: strengths[strength].name}) }}</div>
    <div class="mt-2" v-if="strength < 3">{{ $t("password.someTipForYou") }}
      <ul class="text-left mt-2 pl-4">
        <li :class="validation.length ? 'text-success' : 'text-danger'">{{ $t("password.useAtLeast8Characters") }}</li>
        <li :class="validation.lowerCase ? 'text-success' : 'text-danger'">{{ $t("password.useAtLeast1LowerCaseLetter") }}</li>
        <li :class="validation.upperCase ? 'text-success' : 'text-danger'">{{ $t("password.useAtLeast1CapitalLetter") }}</li>
        <li :class="validation.number ? 'text-success' : 'text-danger'">{{ $t("password.useAtLeast1Number") }}</li>
        <li :class="validation.special ? 'text-success' : 'text-danger'">{{ $t("password.useAtLeast1SpecialCharacter") }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Object,
    class: String,
    id: String,
    placeholder: String,
  },
  data() {
    return {
      modelValueData: this.modelValue,
      className: this.class,
      strengths: [
        {
          name: "very weak",
          color: "text-danger fw-bold",
        },
        {
          name: "weak",
          color: "text-danger",
        },
        {
          name: "average",
          color: "text-primary",
        },
        {
          name: "strong",
          color: "text-success",
        }
      ],
      strength: 0,
      validation: {
        length: false,
        lowerCase: false,
        upperCase: false,
        number: false,
        special: false,
        oftenUsed: false,
      },
    };
  },
  emits: ["update:modelValue"],
  watch: {
    modelValueData() {
      const oftenUsed = [
        "password",
        "picture",
        "asd123",
        "123asd",
        "jelszó",
        "jelszo",
      ];
      this.validation.length = this.modelValueData.length >= 8;
      this.validation.lowerCase = new RegExp('[a-z]').test(this.modelValueData);
      this.validation.upperCase = new RegExp('[A-Z]').test(this.modelValueData);
      this.validation.number = new RegExp('[0-9]').test(this.modelValueData);
      this.validation.special = new RegExp('[^A-Za-z0-9]').test(this.modelValueData);
      this.validation.oftenUsed = oftenUsed.some((word) => this.modelValueData.toLowerCase().includes(word));

      let complex = 0;
      if (this.validation.lowerCase) {
        complex++;
      }
      if (this.validation.upperCase) {
        complex++;
      }
      if (this.validation.number) {
        complex++;
      }
      if (this.validation.special) {
        complex++;
      }

      if (this.validation.oftenUsed && this.modelValueData.length < 11) {
        this.strength = 0;
      } else if (this.validation.oftenUsed && this.modelValueData.length < 14) {
        this.strength = 1;
      } else if (this.validation.number && !(this.validation.lowerCase || this.validation.upperCase)) {
        this.strength = 0;
      } else if (this.modelValueData.length > 15) {
        this.strength = 3;
      } else if (this.modelValueData.length < 8) {
        this.strength = 0;
      } else if (complex > 3 && this.modelValueData.length < 10) {
        this.strength = 2;
      } else if (complex > 2 && this.modelValueData.length < 10) {
        this.strength = 1;
      } else if (this.modelValueData.length < 10) {
        this.strength = 0;
      } else if (complex > 3 && this.modelValueData.length < 12) {
        this.strength = 3;
      } else if (complex > 2 && this.modelValueData.length < 12) {
        this.strength = 2;
      } else if (complex > 1 && this.modelValueData.length < 12) {
        this.strength = 1;
      } else if (this.modelValueData.length < 12) {
        this.strength = 0;
      } else if (complex > 2) {
        this.strength = 3;
      } else {
        this.strength = 2;
      }

      this.$emit("update:modelValue", this.modelValueData);
    },
  },
  name: "Password",
};
</script>